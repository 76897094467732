import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, useMediaQuery } from "../../node_modules/@material-ui/core/index";

const ControlLogin = () => {
  const navigate = useNavigate();

  // 전역변수
  let sessionStorage = window.sessionStorage.getItem("isLogin");
  let userName = window.sessionStorage.getItem("userName");
  let userAuth = "";

  switch (window.sessionStorage.getItem("userAuth")) {
    case "SA":
      userAuth = "실크레딧 관리자";
      break;
    case "SC":
      userAuth = "실크레딧 업체";
      break;
    case "SF":
      userAuth = "실크레딧 가맹점";
      break;
    default:
      userAuth = "권한 없음";
  }

  useEffect(() => {
    if (!sessionStorage) {
      logOut();
    }
  }, []);

  const logOut = () => {
    window.sessionStorage.removeItem("isLogin");
    window.sessionStorage.removeItem("jwt");
    navigate("/authentication/sign-in");
  };

  const matches = useMediaQuery("(min-width:600px)");

  return window.sessionStorage.getItem("isLogin") ? (
    <Box>
      {matches ? (
        <>
          <h6>{userName} 님 로그인중입니다.</h6>
          <h6>Lv : {userAuth}</h6>
        </>
      ) : (
        <>
          <h6>{userName}</h6>
          <h6>Lv : {userAuth}</h6>
        </>
      )}
    </Box>
  ) : (
    <>로그아웃됨</>
  );
};

export default ControlLogin;
