import React, { Fragment, useReducer, useRef } from "react";

import {
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Button,
  FormControl,
} from "@material-ui/core";
import { IpFlagFn } from "utils/ipFlag";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";

import mnworks_logo from "../../../assets/images/mnworks_logo.png";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { axiosLoginInstance } from "../../../hook/axiosInstance";
import useCookies from "../../../../node_modules/react-cookie/cjs/useCookies";
import { CardActions } from "../../../../node_modules/@material-ui/core/index";
import axios from "../../../../node_modules/axios/index";
import { ConstructionOutlined } from "../../../../node_modules/@mui/icons-material/index";

const SignIn = () => {
  const authList = {
    SC: {
      "0200": "Y",
      "0210": "Y",
      "0220": "Y",
      "0230": "Y",
      "0240": "Y",
      "0250": "N",
      "0260": "N",
      "0300": "Y",
      "0310": "N",
      "0320": "N",
      "0330": "N",
      "0340": "N",
      "0350": "Y",
      "0360": "Y",
      "0400": "N",
      "0410": "N",
      "0420": "N",
      "0500": "Y",
      "0510": "Y",
      "0520": "N",
      "0530": "N",
      "0600": "Y",
      "0610": "Y",
      "0620": "N",
      "0700": "Y",
      "0710": "Y",
      "0720": "N",
      "0800": "Y",
      "0810": "Y",
      "0820": "N",
      "0900": "N",
      "0910": "N",
    },
    SCLEVEL12: {
      "0200": "Y",
      "0210": "Y",
      "0220": "Y",
      "0230": "Y",
      "0240": "Y",
      "0250": "Y",
      "0260": "N",
      "0300": "Y",
      "0310": "N",
      "0320": "N",
      "0330": "N",
      "0340": "N",
      "0350": "Y",
      "0360": "Y",
      "0400": "N",
      "0410": "N",
      "0420": "N",
      "0500": "N",
      "0510": "N",
      "0520": "N",
      "0530": "N",
      "0600": "Y",
      "0610": "Y",
      "0620": "Y",
      "0700": "Y",
      "0710": "Y",
      "0720": "N",
      "0800": "Y",
      "0810": "Y",
      "0820": "N",
      "0900": "Y",
      "0910": "Y",
    },
    SF: {
      "0200": "Y",
      "0210": "Y",
      "0220": "Y",
      "0230": "Y",
      "0240": "Y",
      "0250": "Y",
      "0260": "N",
      "0300": "Y",
      "0310": "N",
      "0320": "N",
      "0330": "N",
      "0340": "N",
      "0350": "Y",
      "0360": "N",
      "0400": "N",
      "0410": "N",
      "0420": "N",
      "0500": "N",
      "0510": "N",
      "0520": "N",
      "0530": "N",
      "0600": "N",
      "0610": "N",
      "0620": "N",
      "0700": "Y",
      "0710": "Y",
      "0720": "N",
      "0800": "Y",
      "0810": "Y",
      "0820": "N",
      "0900": "Y",
      "0910": "Y",
    },
    SA: {
      "0200": "Y",
      "0210": "Y",
      "0220": "Y",
      "0230": "Y",
      "0240": "Y",
      "0250": "N",
      "0260": "N",
      "0300": "Y",
      "0310": "Y",
      "0320": "Y",
      "0330": "Y",
      "0340": "Y",
      "0350": "Y",
      "0360": "Y",
      "0400": "Y",
      "0410": "Y",
      "0420": "Y",
      "0500": "Y",
      "0510": "Y",
      "0520": "Y",
      "0530": "Y",
      "0600": "Y",
      "0610": "Y",
      "0620": "Y",
      "0700": "Y",
      "0710": "Y",
      "0720": "Y",
      "0800": "Y",
      "0810": "Y",
      "0820": "Y",
      "0900": "N",
      "0910": "N",
      // "0910": "N",
      // "0910": "N",
    },
    SAIP: {
      "0200": "Y",
      "0210": "Y",
      "0220": "Y",
      "0230": "Y",
      "0240": "Y",
      "0250": "N",
      "0260": "N",
      "0300": "Y",
      "0310": "Y",
      "0320": "Y",
      "0330": "Y",
      "0340": "Y",
      "0350": "Y",
      "0360": "Y",
      "0400": "Y",
      "0410": "Y",
      "0420": "Y",
      "0500": "Y",
      "0510": "Y",
      "0520": "N",
      "0530": "Y",
      "0600": "Y",
      "0610": "Y",
      "0620": "N",
      "0700": "Y",
      "0710": "Y",
      "0720": "N",
      "0800": "Y",
      "0810": "Y",
      "0820": "Y",
      "0900": "N",
      "0910": "N",
      // "0910": "N",
      // "0910": "N",
    },
  };
  // ! 고고페이 로직
  let navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    userId: "",
    userPw: "",
  });

  const handleInputValue = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  // ^ ip
  const [ipFlag, setipFlag] = useState(false);
  const [IP, setIP] = useState("");
  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };
  useEffect(() => getIP(), []);

  const loginApi = async () => {
    try {
      if (checkRemember) {
        setCookie("rememberId", loginData.userId);
      } else {
        removeCookie("rememberId");
      }
      const loginResData = await axiosLoginInstance.post(
        "/auth/login/web",
        loginData
      );
      if (loginResData.data.data.user.userAuth === "SA")
        setipFlag(IpFlagFn(IP));
      if (loginResData.data.header.result_code == 200) {
        sessionStorage.setItem("isLogin", true);
        sessionStorage.setItem("jwt", loginResData.data.data.token.token);
        sessionStorage.setItem(
          "userName",
          loginResData.data.data.user.agentName
        );
        sessionStorage.setItem("userId", loginResData.data.data.user.userId);
        if (loginResData.data.data.user.userAuth === "SC") {
          if (
            loginResData.data.data.user.level === "1" ||
            loginResData.data.data.user.level === "2"
          ) {
            if (loginResData.data.data.user.canRegister === "Y") {
              const auth = "SCLEVEL12";
              sessionStorage.setItem("auth", JSON.stringify(authList[auth]));
              sessionStorage.setItem("userAuth", "SCLEVEL12");
            } else {
              sessionStorage.setItem(
                "auth",
                JSON.stringify(authList[loginResData.data.data.user.userAuth])
              );
              sessionStorage.setItem(
                "userAuth",
                loginResData.data.data.user.userAuth
              );
            }
          } else {
            sessionStorage.setItem(
              "auth",
              JSON.stringify(authList[loginResData.data.data.user.userAuth])
            );
            sessionStorage.setItem(
              "userAuth",
              loginResData.data.data.user.userAuth
            );
          }
        } else {
          sessionStorage.setItem(
            "userAuth",
            loginResData.data.data.user.userAuth
          );
          const auth = ipFlag
            ? "SAIP"
            : authList[loginResData.data.data.user.userAuth];
          // const auth = authList[loginResData.data.data.user.userAuth];
          sessionStorage.setItem("auth", JSON.stringify(auth));
        }

        sessionStorage.setItem("mid", loginResData.data.data.user.mid);
        sessionStorage.setItem("tid", loginResData.data.data.user.tid);
        sessionStorage.setItem("level", loginResData.data.data.user.level);
        navigate("/");
      }
    } catch (err) {
      if (err.response.data.status === 500) {
        alert("잘못된 아이디 비밀번호입니다");
        setLoginData({ userId: "", userPw: "" });
        return;
      }
      if (err.response.data.status === 509) {
        alert("존재하지 않는 회원입니다");
        setLoginData({ userId: "", userPw: "" });
        return;
      }
    }
  };

  const handleKeyPress = (event) => {
    event.key === "Enter" && loginApi();
  };
  // ! 쿠키
  const [cookies, setCookie, removeCookie] = useCookies(["rememberId"]); // ^ react-cookies 이름
  const [checkRemember, setCheckRemember] = useState(true);

  const handleCheckBox = (event) => {
    setCheckRemember(event.target.checked);
  };

  /*페이지가 최초 렌더링 될 경우*/
  useEffect(() => {
    /*저장된 쿠키값이 있으면, CheckBox TRUE 및 UserID에 값 셋팅*/
    if (cookies.rememberId !== undefined) {
      setLoginData({ ...loginData, userId: cookies.rememberId });
      setCheckRemember(true);
      passwordRef.current.focus();
    } else {
      idRef.current.focus();
    }
  }, []);

  // ! ref - 처음에 인풋 활성화
  const idRef = useRef();
  const passwordRef = useRef();

  return (
    <Fragment>
      <div
        className='app-wrapper min-vh-100 min-vw-50'
        style={{ backgroundColor: "#F7F7F7" }}
      >
        <div className='app-main flex-column'>
          <div className='app-content p-0'>
            <div className='app-content--inner d-flex align-items-center'>
              <div className='flex-grow-1 w-100 d-flex align-items-center'>
                <div className='bg-composed-wrapper--content py-5'>
                  <Container maxWidth='lg'>
                    <Grid container spacing={5}>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className='d-none d-lg-flex align-items-center'
                      >
                        <img
                          alt='...'
                          className='w-100 mx-auto d-block img-fluid'
                          // src={svgImage9}
                          src={mnworks_logo}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        className='d-flex flex-column align-items-center'
                      >
                        <span className='w-100 text-left text-md-center pb-4'>
                          <h1 className='display-3 text-xl-left text-center mb-3 font-weight-bold'>
                            MNWORKS 정산관리
                          </h1>
                          {/* <p className="font-size-lg text-xl-left text-center mb-0 text-black-50">
                              We're glad you're working on your app. Login below
                              to continue.
                            </p> */}
                        </span>
                        <Card className='m-0 w-100 p-0 border-0'>
                          {/* <div className="card-header d-block p-3 mx-2 mb-0 mt-2 rounded border-0">
                              <div className="text-muted text-center mb-3">
                                <span>Sign in with</span>
                              </div>
                              <div className="text-center">
                                <Button
                                  variant="outlined"
                                  className="mr-2 text-facebook"
                                >
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={["fab", "facebook"]}
                                    />
                                  </span>
                                  <span className="btn-wrapper--label">
                                    Facebook
                                  </span>
                                </Button>
                                <Button
                                  variant="outlined"
                                  className="ml-2 text-twitter"
                                >
                                  <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon
                                      icon={["fab", "twitter"]}
                                    />
                                  </span>
                                  <span className="btn-wrapper--label">
                                    Twitter
                                  </span>
                                </Button>
                              </div>
                            </div> */}
                          <CardContent className='p-3'>
                            <div className='text-center text-black-50 mb-3'>
                              {/* <span>Or sign in with credentials</span> */}
                            </div>
                            <form className='px-5'>
                              <div className='mb-3'>
                                <FormControl className='w-100'>
                                  <InputLabel htmlFor='input-with-icon-adornment'>
                                    아이디
                                  </InputLabel>
                                  <Input
                                    fullWidth
                                    id='input-with-icon-adornment'
                                    name='userId'
                                    onChange={handleInputValue}
                                    inputRef={idRef}
                                    value={loginData.userId}
                                    startAdornment={
                                      <InputAdornment position='start'>
                                        <MailOutlineTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className='mb-3'>
                                <FormControl className='w-100'>
                                  <InputLabel htmlFor='standard-adornment-password'>
                                    비밀번호
                                  </InputLabel>
                                  <Input
                                    id='standard-adornment-password'
                                    fullWidth
                                    name='userPw'
                                    onChange={handleInputValue}
                                    onKeyDown={(event) => handleKeyPress(event)}
                                    type='password'
                                    inputRef={passwordRef}
                                    value={loginData.userPw}
                                    startAdornment={
                                      <InputAdornment position='start'>
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </div>
                              <div className='w-100'>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkRemember}
                                      onChange={handleCheckBox}
                                      value='checkRemember'
                                      color='primary'
                                    />
                                  }
                                  label='아이디 저장'
                                />
                              </div>
                              <div className='text-center'>
                                <Button
                                  color='primary'
                                  variant='contained'
                                  size='large'
                                  className='my-2'
                                  onClick={loginApi}
                                >
                                  로그인
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                        <CardActions
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <h6>문의 : 070-8633-5364</h6>
                          <h6>접속 IP : {IP}</h6>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </PageLayout> */}
    </Fragment>
  );
};
export default SignIn;
