import React, { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Fab, Drawer, IconButton, Button } from "@material-ui/core";

import projectLogo from "../../assets/images/mnworks_logo.png";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import {
  AppBar,
  Box,
  Tooltip,
} from "../../../node_modules/@material-ui/core/index";

import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import ListIcon from "@material-ui/icons/List";

import clsx from "clsx";
import HeaderLogo from "./HeaderLogo";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";
import ControlLogin from "utils/controlLogin";
import { Link } from "react-router-dom";
import DrawerSidebar from "components/BootstrapSidebar/DrawerSidebar";

const Header = ({ handleSidebarClose, sidebarOpen, isCollapsedLayout }) => {
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  // ! 로그아웃
  const navigate = useNavigate();

  const logOut = () => {
    window.sessionStorage.clear();
    navigate("/authentication/sign-in");
  };

  return (
    <>
      <AppBar position="fixed" elevation={5}>
        <div
          className="header-nav-wrapper header-nav-wrapper-md rounded-sm navbar-dark bg-midnight-bloom"
          style={{
            display: "flex",
            justifyContent: "space-between",
            "@media (maxWidth : 1300px)": {
              width: "110%",
            },
          }}
        >
          <div
            style={{
              width: "280px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // justifyContent: "space-between",
            }}
            className="header-nav-logo"
          >
            <div
              className={
                sidebarOpen
                  ? "nav-logo app-header-logo"
                  : "app-header-logo app-header-logo-close"
              }
              style={{ width: "230px" }}
            >
              {!isCollapsedLayout && <HeaderLogo sidebarOpen={sidebarOpen} />}

              <Link
                // to="/"
                className="d-flex align-items-center link-dark text-decoration-none"
              >
                <i className="bg-white">
                  <img
                    alt="Carolina React Admin Dashboard with Material-UI PRO"
                    src={projectLogo}
                  />
                </i>
                {/* <svg className="bi me-2" width="30" height="24"></svg> */}
                {/* {sidebarOpen ? ( */}
                <span style={{ color: "whitesmoke" }}>MNWORKS</span>
                {/* ) : null} */}
              </Link>
            </div>
            <Box
              sx={{ display: { xs: "block", sm: "none" } }}
              className="nav-logo"
            >
              <Link
                to="/"
                className="d-flex align-items-center link-dark text-decoration-none"
                style={{ marginLeft: "15px" }}
              >
                <i className="bg-white">
                  <img
                    alt="Carolina React Admin Dashboard with Material-UI PRO"
                    src={projectLogo}
                    className="bi me-2"
                    style={{
                      width: "30px",
                      height: "24px",
                    }}
                  />
                </i>
                <span style={{ color: "whitesmoke" }}>MNWORKS</span>
              </Link>
            </Box>
            <Box
              className={clsx(
                "btn-toggle-collapse",
                // "toggle-sidebar-btn-mobile",
                {
                  "btn-toggle-collapse-closed": sidebarOpen,
                }
              )}
              // style={{ paddingLeft: "80px" }}
            >
              <Tooltip
                title={sidebarOpen ? "접기" : "펼치기"}
                placement="right"
              >
                <IconButton
                  color="inherit"
                  onClick={handleSidebarClose}
                  size="medium"
                  className="btn-inverse"
                  style={{ color: "white" }}
                >
                  {!sidebarOpen ? <ListIcon /> : <MenuOpenRoundedIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </div>
          {/* <div
            className="header-nav-menu d-none d-lg-block d-flex align-center"
            // style={{ padding: "0 0 20px 0" }}
          ></div> */}
          {/* <div
            className="d-flex justify-content-end position-absolute"
            style={{ width: "70vw" }}
          > */}
          <div className="header-nav-actions">
            <Box className="app-logo-text" style={{ color: "white" }}>
              <ControlLogin />
            </Box>
          </div>
          <span className="d-none d-sm-block">
            <Button
              color="inherit"
              variant="outlined"
              className="text-white ml-3"
              onClick={logOut}
              style={{ width: "100px" }}
            >
              로그아웃
            </Button>
          </span>
          &nbsp;&nbsp;&nbsp;
          <span className="d-block d-lg-none">
            <Fab
              onClick={toggleDrawer("left", true)}
              color="primary"
              size="medium"
            >
              <MenuRoundedIcon style={{ color: "white" }} />
            </Fab>
          </span>
        </div>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={state.left}
        onClose={toggleDrawer("left", false)}
        elevation={11}
        style={{ marginTop: "100px" }}
        className="Drawer"
      >
        <DrawerSidebar />
      </Drawer>
    </>
  );
};

export default Header;
