import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Divider,
  InputAdornment,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Grid,
  // Box,
} from "@material-ui/core";
import { FormLabel, Button } from "@mui/material";

import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import CommonTextFieldFullwidthPoint from "components/commonInput/CommonTextFieldPoint";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { onlyInputNumber } from "utils/validationCheckFunction";
// import { faL } from "../../../../node_modules/@fortawesome/free-solid-svg-icons/index";
import { postAxios, getAxios } from "hook/axiosInstance";
// import CommonAccountNumberField from "components/commonInput/CommonAccountNumberField";

const ServiceInput = ({
  createValue,
  isAccountValidate,
  handleChange,
  error,
  helperText,
  handleCheck,
  validateBankAccount,
  accountNumberFlag,
  setCreateValue,
}) => {
  const [point, setPoint] = useState();
  // const [limit, setLimit] = useState(15);

  const handleChangePoint = (event) => {
    setPoint(event.target.value);
  };
  const pgChange = (e) => {
    const getPgFlag = findPgFlagById(e.target.value);
    if (getPgFlag === "N") {
      setCreateValue({
        ...createValue,
        [e.target.name]: e.target.value,
        pgFlag: getPgFlag,
        paymentType: getPgFlag,
      });
    } else {
      setCreateValue({
        ...createValue,
        [e.target.name]: e.target.value,
        pgFlag: getPgFlag,
        paymentType: getPgFlag,
        samsungPayYN: "N",
      });
    }
  };

  const vanChange = (e) => {
    setCreateValue({
      ...createValue,
      [e.target.name]: e.target.value,
    });
  };

  const findPgFlagById = (id) => {
    const item = pgList.find((item) => item.pgId === id);
    return item ? item.pgFlag : null;
  };

  const updatePoint = async () => {
    const mid = createValue.mid;

    const postData = {
      mid: mid,
      point: point,
    };
    const updatePointApi = await postAxios("franchise/account/mount", postData);
    if (updatePointApi.data.data === "Y") {
      setPoint(0);
      alert("포인트 충전 되었습니다.");
      return;
    } else {
      alert("서버 에러입니다.");
      return;
    }
  };
  // useEffect(() => {
  //   if (createValue)
  //     setPgCompanyValue(
  //       `${createValue?.pgCompany?.pgCompanyName?.pgName}( ${
  //         createValue?.pgCompany?.mid
  //       } ) ${createValue?.pgCompany?.pgFlag === "Y" ? "앱결제" : "단말기"}`
  //     );
  // }, [createValue]);
  const getPgList = async () => {
    await getAxios("common/pg/list")
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          const tempList = [];
          data.data.map((obj) => {
            return tempList.push({
              ...obj,
              title: `${obj.pgName} ${ynwtvMapFn(obj.pgFlag)}`,
            });
          });
          setPgList(tempList);
        }
      })
      .catch((_err) => {});
  };
  const [pgList, setPgList] = useState([]);
  useEffect(() => {
    if (pgList.length < 1) getPgList();
  }, []);

  const checkAccountNumberFn = async (createValue) => {
    const accountNumber = createValue?.accountNumber;

    if (!accountNumber) {
      alert("계좌번호를 입력해주세요");
      return;
    }
    const resDuplicate = await getAxios(
      `franchise/account/duplication?accountNumber=${accountNumber}`
    );
    if (resDuplicate.data.data === "Y") {
      alert("중복되지 않은 계좌번호입니다.");
    } else {
      alert("중복된 계좌번호가 존재합니다.");
    }
  };
  const removeSpaces = (e) => {
    return e.replace(/\s+/g, "");
  };

  return (
    <Card className='p-4 mb-4'>
      <div className='font-size-lg font-weight-bold'>
        서비스 설정 및 정산계좌 정보
      </div>
      <Divider className='my-2' />
      <Grid container spacing={2}>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              fullWidth
              className='m-2'
              id='pgCompanyId'
              name='pgCompanyId'
              variant='outlined'
              select
              // label="PG"
              value={createValue?.pgCompanyId ? createValue.pgCompanyId : 0}
              onChange={pgChange}
              helperText={helperText?.pgCompanyId}
              error={error?.pgCompanyId}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    PG&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
            >
              {pgList.map((option) => (
                <MenuItem key={option.pgId} value={option.pgId}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </StyleBox>
        </Grid>

        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              fullWidth
              className='m-2'
              id='contractStatus'
              name='contractStatus'
              variant='outlined'
              select
              // label="계약상태"
              value={createValue?.contractStatus * 1}
              onChange={handleChange}
              helperText={helperText?.contractStatus}
              error={error?.contractStatus}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    계약상태&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
            >
              {contractStatusList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              id='commissionRate'
              name='commissionRate'
              fullWidth
              className={"m-2"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    수수료율&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              variant='outlined'
              onChange={handleChange}
              value={createValue?.commissionRate}
              error={error?.commissionRate}
              placeholder='숫자 및 소수점만 입력(예: 8.8)'
              helperText={
                helperText?.commissionRate || "숫자 및 소수점만 입력(예: 8.8)"
              }
              // placeholder="숫자 및 소수점만 입력(예: 8.8)"
            />
            {/* <FormHelperText id="outlined-weight-helper-text">
                숫자 및 소수점만 입력(예: 8.8)
              </FormHelperText> */}
          </StyleBox>
        </Grid>

        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              // sx={{ width: "100%" }}
              fullWidth
              className='m-2'
              id='settlementCycle'
              name='settlementCycle'
              variant='outlined'
              select
              // label="정산주기"
              value={createValue?.settlementCycle}
              onChange={handleChange}
              helperText={helperText?.settlementCycle}
              error={error?.settlementCycle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    정산주기&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              <MenuItem key={"신청"} value={"0"}>
                {"D + 0"}
              </MenuItem>
              <MenuItem key={"신청"} value={"1"}>
                {"D + 1"}
              </MenuItem>
              <MenuItem key={"D + 3"} value={"3"}>
                {"D + 3"}
              </MenuItem>
              <MenuItem key={"D + 4"} value={"4"}>
                {"D + 4"}
              </MenuItem>
              <MenuItem key={"D + 5"} value={"5"}>
                {"D + 5"}
              </MenuItem>
            </TextField>
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              className='m-2'
              id='installmentPending'
              name='installmentPending'
              variant='outlined'
              select
              fullWidth
              // label="할부보류"
              value={createValue?.installmentPending}
              onChange={handleChange}
              helperText={helperText?.installmentPending}
              error={error?.installmentPending}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    할부보류&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {installmentPending.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              className='m-2'
              id='paymentLimitForeach'
              name='paymentLimitForeach'
              variant='outlined'
              fullWidth
              label={
                <span>
                  결제한도(건)
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              value={createValue?.paymentLimitForeach}
              onChange={handleChange}
              error={error?.paymentLimitForeach}
              helperText={helperText?.paymentLimitForeach}
            />
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              className='m-2'
              id='paymentLimitMonth'
              name='paymentLimitMonth'
              variant='outlined'
              fullWidth
              label={
                <span>
                  결제한도(월)
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              value={createValue?.paymentLimitMonth}
              onChange={handleChange}
              error={error?.paymentLimitMonth}
              helperText={helperText?.paymentLimitMonth}
            />
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              className='m-2'
              id='paymentLimitDay'
              name='paymentLimitDay'
              variant='outlined'
              fullWidth
              label={
                <span>
                  결제한도(일)
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              value={createValue?.paymentLimitDay}
              onChange={handleChange}
              error={error?.paymentLimitDay}
              helperText={helperText?.paymentLimitDay}
            />
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              className='m-2'
              id='paymentCardLimit'
              name='paymentCardLimit'
              variant='outlined'
              fullWidth
              label={
                <span>
                  동일카드 결제한도(일)
                  <FontAwesomeIcon
                    icon='fa-solid fa-asterisk'
                    size='xs'
                    color='red'
                  />
                </span>
              }
              value={createValue?.paymentCardLimit}
              onChange={handleChange}
              error={error?.paymentCardLimit}
              helperText={helperText?.paymentCardLimit}
            />
          </StyleBox>
        </Grid>
        <Grid xs={12}>
          <FormLabel className='m-2'>
            로그인&nbsp;
            <FontAwesomeIcon
              icon='fa-solid fa-asterisk'
              size='xs'
              color='red'
            />
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={createValue?.loginAllowCheck === "Y"}
                onChange={handleCheck}
              />
            }
            name='loginAllowCheck'
            id='loginAllowCheck'
            label={createValue?.loginAllowCheck === "Y" ? "허용" : "차단"}
            // onClick={handleCheck}
            helperText={helperText?.loginAllowCheck}
            error={error?.loginAllowCheck}
          />
          <Grid xs={6}>
            <FormLabel className='m-2'>
              통신비 가맹점 지불여부&nbsp;
              <FontAwesomeIcon
                icon='fa-solid fa-asterisk'
                size='xs'
                color='red'
              />
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  checked={createValue?.callCharges === "Y"}
                  onChange={handleCheck}
                />
              }
              name='callCharges'
              id='callCharges'
              label={createValue?.callCharges === "Y" ? "지불" : "미지불"}
              // onClick={handleCheck}
              helperText={helperText?.callCharges}
              error={error?.callCharges}
            />
          </Grid>

          <Grid xs={6}>
            <FormLabel className='m-2'>
              단골고객등록 사용여부&nbsp;
              <FontAwesomeIcon
                icon='fa-solid fa-asterisk'
                size='xs'
                color='red'
              />
            </FormLabel>
            <FormControlLabel
              control={
                <Switch
                  color='primary'
                  checked={createValue?.franchiseCardYn === "Y"}
                  onChange={handleCheck}
                />
              }
              name='franchiseCardYn'
              id='franchiseCardYn'
              label={createValue?.franchiseCardYn === "Y" ? "사용" : "미사용"}
              // onClick={handleCheck}
              helperText={helperText?.franchiseCardYn}
              error={error?.franchiseCardYn}
            />
          </Grid>
          <FormLabel className='m-2'>
            결제취소&nbsp;
            <FontAwesomeIcon
              icon='fa-solid fa-asterisk'
              size='xs'
              color='red'
            />
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={createValue?.paymentCancelTodayYn === "Y"}
                onChange={handleCheck}
              />
            }
            name='paymentCancelTodayYn'
            id='paymentCancelTodayYn'
            label={
              createValue?.paymentCancelTodayYn === "N" ? "당일 X" : "당일"
            }
            helperText={helperText?.paymentCancelTodayYn}
            error={error?.paymentCancelTodayYn}
          />
          <FormLabel className='m-2'>
            정산보류&nbsp;
            <FontAwesomeIcon
              icon='fa-solid fa-asterisk'
              size='xs'
              color='red'
            />
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={createValue?.settlementPendingYn === "Y"}
                onChange={handleCheck}
              />
            }
            name='settlementPendingYn'
            id='settlementPendingYn'
            label={
              createValue?.settlementPendingYn === "N" ? "보류 X" : "보류체크"
            }
            helperText={helperText?.settlementPendingYn}
            error={error?.settlementPendingYn}
          />
        </Grid>
        <Grid
          xs={12}
          style={{ display: createValue.pgFlag === "N" ? "block" : "none" }}
        >
          <FormLabel className='m-2'>
            삼성페이 사용여부&nbsp;
            <FontAwesomeIcon
              icon='fa-solid fa-asterisk'
              size='xs'
              color='red'
            />
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                color='primary'
                checked={createValue?.samsungPayYN === "Y"}
                onChange={handleCheck}
              />
            }
            name='samsungPayYN'
            id='samsungPayYN'
            label={createValue?.samsungPayYN === "Y" ? "사용" : "미사용"}
            // onClick={handleCheck}
            helperText={helperText?.samsungPayYN}
            error={error?.samsungPayYN}
          />
        </Grid>

        <Grid
          xs={6}
          style={{
            display: createValue?.samsungPayYN === "Y" ? "block" : "none",
          }}
        >
          <StyleBox>
            <TextField
              size='small'
              fullWidth
              className='m-2'
              id='vanpgseq'
              name='vanpgseq'
              variant='outlined'
              select
              // label="PG"
              value={createValue?.vanpgseq ? createValue.vanpgseq : 0}
              onChange={vanChange}
              helperText={helperText?.vanpgseq}
              error={error?.vanpgseq}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    VAN&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
            >
              {pgList.map((option) => (
                // if(option.pgId==='as')
                <MenuItem
                  style={{ display: option.pgFlag === "T" ? "block" : "none" }}
                  key={option.pgId}
                  value={option.pgId}
                >
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </StyleBox>
        </Grid>
        <Grid
          xs={6}
          style={{
            display: createValue?.samsungPayYN === "Y" ? "block" : "none",
          }}
        >
          <StyleBox>
            <TextField
              size='small'
              // sx={{ width: "100%" }}
              fullWidth
              className='m-2'
              id='vanSettlementCycle'
              name='vanSettlementCycle'
              variant='outlined'
              select
              // label="정산주기"
              value={`${createValue?.vanSettlementCycle}`}
              onChange={handleChange}
              helperText={helperText?.vanSettlementCycle}
              error={error?.vanSettlementCycle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    정산주기&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              <MenuItem key={"신청"} value={"1"}>
                {"D + 1"}
              </MenuItem>
              <MenuItem key={"D + 3"} value={"3"}>
                {"D + 3"}
              </MenuItem>
              <MenuItem key={"D + 4"} value={"4"}>
                {"D + 4"}
              </MenuItem>
              <MenuItem key={"D + 5"} value={"5"}>
                {"D + 5"}
              </MenuItem>
            </TextField>
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <TextField
              size='small'
              // sx={{ width: "3000px" }}
              className='m-2'
              id='bankName'
              name='bankName'
              variant='outlined'
              select
              fullWidth
              value={removeSpaces(createValue?.bankName)}
              onChange={handleChange}
              helperText={helperText?.bankName}
              error={error?.bankName}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    입금은행명&nbsp;
                    <FontAwesomeIcon
                      icon='fa-solid fa-asterisk'
                      size='xs'
                      color='red'
                    />
                  </InputAdornment>
                ),
              }}
            >
              {bankNameList.map((option) => (
                <MenuItem
                  key={removeSpaces(option.value)}
                  value={removeSpaces(option.value)}
                  sx={{ not: true, width: "100%" }}
                >
                  {removeSpaces(option.label)}
                </MenuItem>
              ))}
            </TextField>
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={helperText}
              error={error}
              field='accountHolder'
              meaning='예금주'
              required={true}
            />
          </StyleBox>
        </Grid>
        <Grid xs={6}>
          <StyleBox>
            <CommonTextFieldFullwidth
              handleChange={handleChange}
              createValue={createValue}
              helperText={""}
              error={false}
              field='accountNumber'
              meaning='계좌번호'
              required={true}
              disabled={accountNumberFlag}
            />
            <Button
              variant='contained'
              color='primary'
              className='m-2'
              size='small'
              onClick={() => validateBankAccount(createValue)}
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
            >
              계좌인증
            </Button>
            <Button
              variant='contained'
              color='primary'
              className='m-2'
              size='small'
              onClick={() => checkAccountNumberFn(createValue)}
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
            >
              계좌중복확인
            </Button>
          </StyleBox>
          <span className='text-danger'>
            ※ 계좌인증을 하셔야 정산금을 이체할 수 있습니다..
          </span>
          <div>
            계좌인증 여부 :{" "}
            <span
              className={
                isAccountValidate === true ? "text-info" : "text-danger"
              }
            >
              {isAccountValidate === true ? "인증 성공" : "인증 필요"}
            </span>
          </div>
          <StyleBox>
            <CommonTextFieldFullwidthPoint
              handleChange={handleChangePoint}
              createValue={{ point: point }}
              helperText={{ point: "숫자만 입력해주세요" }}
              error={error}
              field='point'
              meaning='포인트'
              required={false}
              type='number'
            />
            <Button
              variant='contained'
              color='primary'
              className='m-2'
              size='small'
              onClick={() => updatePoint()}
              sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
            >
              포인트 입금
            </Button>
          </StyleBox>
        </Grid>
      </Grid>
    </Card>
  );
};

const StyleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default ServiceInput;

const installmentPending = [
  {
    value: "0",
    label: "해당없음",
  },
  {
    value: "1",
    label: "01개월 이상 할부 시 정산보류",
  },
  {
    value: "2",
    label: "02개월 이상 할부 시 정산보류",
  },
  {
    value: "3",
    label: "03개월 이상 할부 시 정산보류",
  },
  {
    value: "4",
    label: "04개월 이상 할부 시 정산보류",
  },
  {
    value: "5",
    label: "05개월 이상 할부 시 정산보류",
  },
  {
    value: "6",
    label: "06개월 이상 할부 시 정산보류",
  },
  {
    value: "7",
    label: "07개월 이상 할부 시 정산보류",
  },
  {
    value: "8",
    label: "08개월 이상 할부 시 정산보류",
  },
  {
    value: "9",
    label: "09개월 이상 할부 시 정산보류",
  },
  {
    value: "10",
    label: "10개월 이상 할부 시 정산보류",
  },
  {
    value: "11",
    label: "11개월 이상 할부 시 정산보류",
  },
  {
    value: "12",
    label: "12개월 이상 할부 시 정산보류",
  },
];

const bankNameList = [
  { label: "웰컴저축은행", value: "웰컴저축은행" },
  { label: "한국은행", value: "한국은행" },
  { label: "KDB산업은행", value: "KDB산업은행" },
  { label: "IBK기업은행", value: "IBK기업은행" },
  { label: "KB국민은행", value: "KB국민은행" },
  { label: "Sh수협은행", value: "Sh수협은행" },
  { label: "한국수출입은행", value: "한국수출입은행" },
  { label: "NH농협은행", value: "NH농협은행" },
  { label: "지역농축협", value: "지역농축협" },
  { label: "우리은행", value: "우리은행" },
  { label: "SC제일은행", value: "SC제일은행" },
  { label: "한국씨티은행", value: "한국씨티은행" },
  { label: "대구은행", value: "대구은행" },
  { label: "부산은행", value: "부산은행" },
  { label: "광주은행", value: "광주은행" },
  { label: "제주은행", value: "제주은행" },
  { label: "전북은행", value: "전북은행" },
  { label: "경남은행", value: "경남은행" },
  { label: "새마을금고", value: "새마을금고" },
  { label: "신용협동조합", value: "신용협동조합" },
  { label: "상호저축은행", value: "상호저축은행" },
  { label: "HSBC", value: "HSBC" },
  { label: "산림조합", value: "산림조합" },
  { label: "우체국", value: "우체국" },
  { label: "KEB하나은행", value: "KEB하나은행" },
  { label: "신한은행", value: "신한은행" },
  { label: "케이뱅크", value: "케이뱅크" },
  { label: "카카오뱅크", value: "카카오뱅크" },
  { label: "토스뱅크", value: "토스뱅크" },
  { label: "유안타증권", value: "유안타증권" },
  { label: "KB증권", value: "KB증권" },
  { label: "골든브릿지투자증권", value: "골든브릿지투자증권" },
  { label: "한양증권", value: "한양증권" },
  { label: "리딩투자증권", value: "리딩투자증권" },
  { label: "BNK투자증권", value: "BNK투자증권" },
  { label: "IBK투자증권", value: "IBK투자증권" },
  { label: "KTB투자증권", value: "KTB투자증권" },
  { label: "미래에셋대우", value: "미래에셋대우" },
  { label: "삼성증권", value: "삼성증권" },
  { label: "한국투자증권", value: "한국투자증권" },
  { label: "NH투자증권", value: "NH투자증권" },
  { label: "교보증권", value: "교보증권" },
  { label: "하이투자증권", value: "하이투자증권" },
  { label: "HMC투자증권", value: "HMC투자증권" },
  { label: "키움증권", value: "키움증권" },
  { label: "이베스트투자증권", value: "이베스트투자증권" },
  { label: "SK증권", value: "SK증권" },
  { label: "대신증권", value: "대신증권" },
  { label: "한화투자증권", value: "한화투자증권" },
  { label: "하나금융투자", value: "하나금융투자" },
  { label: "신한금융투자", value: "신한금융투자" },
  { label: "동부증권", value: "동부증권" },
  { label: "유진투자증권", value: "유진투자증권" },
  { label: "메리츠종합금융증권", value: "메리츠종합금융증권" },
  { label: "카카오페이증권", value: "카카오페이증권" },
  { label: "부국증권", value: "부국증권" },
  { label: "신영증권", value: "신영증권" },
  { label: "케이프증권", value: "케이프증권" },
];

const contractStatusList = [
  { value: "1", label: "신청" },
  { value: "2", label: "정상" },
  { value: "3", label: "해지" },
];
export const ynwtvMap = {
  Y: "K-App",
  N: "K-TER",
  W: "K-WEP",
  T: "N-TER",
  V: "N-S",
};
export const ynwtvMapFn = (e) => {
  return ynwtvMap[e];
};
