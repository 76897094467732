import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  TextField,
} from "../../../node_modules/@material-ui/core/index";
import {
  now,
  oneMonthAgo,
  threeMonthAgo,
  yesterday,
  threeDaysAgo,
  lastMonthEnd,
  lastMonthStart,
  thisMonthStart,
} from "../../utils/formatFunction";
import { ButtonSelectDateBox } from "utils/ButtonSelectDateBox";
import { listLinkStateAtom, mobileFlagAtom } from "recoilStates/searchRecoil";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getAxios } from "hook/axiosInstance";
import { ynwtvMapFn } from "layouts/franchise/franchiseCreate/ServiceInput";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome/index";
import { useRecoilValue } from "recoil";
export const Search = ({
  dateFlag,
  pageFlag,
  link,
  checkList,
  getListApi,
  limit,
  setPage,
  searchField,
  dateNull,
}) => {
  // const [type, setType] = useState();
  const [startDate, setStartDate] = useState(dateNull ? null : now);
  const [endDate, setEndDate] = useState(dateNull ? null : now);
  const mobileFlag = useRecoilValue(mobileFlagAtom);
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  useEffect(() => {
    setLink();
  }, [link]);

  const setLink = () => {
    if (link) {
      let tempLink = link;
      tempLink += `keyword=`;
      if (pageFlag) tempLink += `&page=1`;
      if (limit) tempLink += `&limit=15`;
      for (const obj of checkList) {
        if (obj === "dateType") tempLink += `&${obj}=paymentDate`;
        else tempLink += `&${obj}=all`;
      }
      if (dateFlag) {
        tempLink += `&startDate=${formatDate(startDate)}&endDate=${formatDate(
          endDate
        )}`;
      }
      setListLinkState(tempLink);
    }
  };
  const searchClick = () => {
    if (pageFlag) {
      let tempLink = listLinkState;
      tempLink = tempLink.replace(/page=[^&]*/, `page=1`);
      setListLinkState(tempLink);
      setPage(1);
      getListApi();
    }
  };
  return (
    <Box p={1} bgcolor='white'>
      <Box
        display='flex'
        justifyContent='flex-end'
        flexDirection={
          mobileFlag === "ta" || mobileFlag === "mo" ? "column" : "row"
        }
      >
        <Box display={dateFlag ? "block" : "none"}>
          <DateMap
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
        <Box
          display='flex'
          flexDirection={mobileFlag === "mo" ? "column" : "row"}
        >
          <Box display='flex'>
            {checkList.includes("pg") ? <PgSelect /> : null}
            <SelectMap checkList={checkList} />
            {searchField ? (
              <SearchFieldMap searchField={searchField} />
            ) : undefined}
          </Box>
          <Box display='flex'>
            <KeywordForm searchClick={searchClick} />
            <Button
              variant='contained'
              color='primary'
              className='m-2 p-2'
              onClick={searchClick}
            >
              검색
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DateMap = ({ setStartDate, setEndDate, startDate, endDate }) => {
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [nowDate, setNowDate] = useState(now);
  const [oneMonthAgoDate, setOneMonthAgo] = useState(oneMonthAgo);
  const [threeMonthAgoDate, setThreeMonthAgo] = useState(threeMonthAgo);
  const [yesterdayDate, setYesterday] = useState(yesterday);
  const [threeDaysAgoDate, setThreeDaysAgo] = useState(threeDaysAgo);
  const [lastMonthEndDate, setLastMonthEnd] = useState(lastMonthEnd);
  const [lastMonthStartDate, setLastMonthStart] = useState(lastMonthStart);
  const [thisMonthStartDate, setThisMonthStart] = useState(thisMonthStart);
  const dateChangeNow = () => {
    setStartDate(now);
    setEndDate(now);
    setDateFn({ sDate: `${formatDate(nowDate)}`, eDate: formatDate(nowDate) });
  };

  const dateChangeYesterday = () => {
    setStartDate(yesterday);
    setEndDate(yesterday);
    setDateFn({
      sDate: formatDate(yesterdayDate),
      eDate: formatDate(yesterdayDate),
    });
  };
  const dateChangeThreeDaysAgo = () => {
    setStartDate(threeDaysAgo);
    setEndDate(now);
    setDateFn({
      sDate: formatDate(threeDaysAgoDate),
      eDate: formatDate(nowDate),
    });
  };

  const dateChangeOneMonthAgo = () => {
    setStartDate(oneMonthAgo);
    setEndDate(now);
    setDateFn({
      sDate: formatDate(oneMonthAgoDate),
      eDate: formatDate(nowDate),
    });
  };

  const dateChangeThreeMonthAgo = () => {
    setStartDate(threeMonthAgo);
    setEndDate(now);
    setDateFn({
      sDate: formatDate(threeMonthAgoDate),
      eDate: formatDate(nowDate),
    });
  };

  const dateChangeThisMonth = () => {
    setStartDate(thisMonthStart);
    setEndDate(now);
    setDateFn({
      sDate: formatDate(thisMonthStartDate),
      eDate: formatDate(nowDate),
    });
  };

  const dateChangeLastMonth = () => {
    setStartDate(lastMonthStart);
    setEndDate(lastMonthEnd);
    setDateFn({
      sDate: formatDate(lastMonthStartDate),
      eDate: formatDate(lastMonthEndDate),
    });
  };

  const dateArr = [
    { title: "오늘", event: dateChangeNow },
    { title: "어제", event: dateChangeYesterday },
    { title: "3일전", event: dateChangeThreeDaysAgo },
    { title: "1개월 전", event: dateChangeOneMonthAgo },
    { title: "3개월 전", event: dateChangeThreeMonthAgo },
    { title: "이번 달", event: dateChangeThisMonth },
    { title: "지난 달", event: dateChangeLastMonth },
  ];
  const setDateFn = ({ sDate, eDate }) => {
    let tempLink = listLinkState;
    if (listLinkState || listLinkState.includes("startDate")) {
      tempLink = tempLink
        .replace(/startDate=[^&]*/, `startDate=${formatDate(sDate)}`)
        .replace(/endDate=[^&]*/, `endDate=${formatDate(eDate)}`);
      setListLinkState(tempLink);
    }
  };

  useEffect(() => {
    setDateFn({
      sDate: formatDate(startDate),
      eDate: formatDate(endDate),
    });
  }, [startDate, endDate]);
  const mobileFlag = useRecoilValue(mobileFlagAtom);

  const dateMap = dateArr.map((obj, i) => {
    return (
      <Button
        key={i}
        className='m-1 p-1'
        variant='outlined'
        size='small'
        color='primary'
        onClick={obj.event}
      >
        {obj.title}
      </Button>
    );
  });
  return (
    <Box
      flexDirection={mobileFlag === "mo" ? "column" : "row"}
      display={"flex"}
    >
      <Box>{dateMap}</Box>
      <ButtonSelectDateBox
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        className='m-2'
      />
    </Box>
  );
};
const SelectMap = ({ checkList }) => {
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const selectArray = [
    { title: "결제상태", arr: payStatus, field: "payStatus" },
    { title: "차감구분", arr: deductType, field: "deductType" },
    { title: "정산구분", arr: settlementYn, field: "settlementYn" },
    { title: "날짜기준", arr: dateType, field: "dateType" },
    { title: "가상계좌", arr: immediate, field: "immediate" },
    { title: "출금여부", arr: withdraw, field: "withdraw" },
    { title: "상태", arr: contractStatus, field: "contractStatus" },
  ];

  const renderOptions = (array) => {
    return array.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ));
  };

  const checkEventHandler = (e) => {
    const { name, value } = e.target;
    let tempLink = listLinkState;
    const selectedOption = selectArray.find((option) => option.field === name);
    if (selectedOption) {
      const param = selectedOption.field;
      tempLink = tempLink.replace(
        new RegExp(`${param}=[^&]*`),
        `${param}=${value}`
      );
      setListLinkState(tempLink);
    }
  };

  const checkMap = checkList.map((item, i) => {
    if (item !== "pg" && item !== "searchField") {
      const { title, arr, field } = selectArray.find(
        (select) => select.field === item
      );
      return (
        <FormControl key={i}>
          <TextField
            name={field}
            className='m-2'
            variant='outlined'
            defaultValue={item === "dateType" ? "paymentDate" : "all"}
            select
            size='small'
            style={{ width: "80px" }}
            label={title}
            onChange={checkEventHandler}
          >
            {renderOptions(arr)}
          </TextField>
        </FormControl>
      );
    }
  });
  return <Box>{checkMap}</Box>;
};

const PgSelect = () => {
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [pgArr, setPgArr] = useState([{ id: "all", name: "모두" }]);
  const getPgList = async () => {
    const tempArr = pgArr;
    const resApiData = await getAxios("common/pg/list");
    if (resApiData.data.header.result_code === "200") {
      resApiData.data.data.map((obj) => {
        return tempArr.push({
          id: obj.pgId,
          name: `${obj.pgName} ${ynwtvMapFn(obj.pgFlag)}`,
        });
      });
      setPgArr(tempArr);
    }
  };
  const checkEventHandler = (e) => {
    const { value } = e.target;
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/pg=[^&]*/, `pg=${value}`);
    setListLinkState(tempLink);
  };
  useEffect(() => {
    getPgList();
  }, []);
  const menuMap = pgArr.map((obj, i) => {
    return (
      <MenuItem key={i} value={obj.id}>
        {obj.name}
      </MenuItem>
    );
  });
  return (
    <FormControl>
      <TextField
        name={"pg"}
        className='m-2'
        variant='outlined'
        defaultValue={"all"}
        select
        size='small'
        label={"PG사"}
        onChange={checkEventHandler}
        style={{ width: "80px" }}
      >
        {menuMap}
      </TextField>
    </FormControl>
  );
};

const SearchFieldMap = ({ searchField }) => {
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);

  const checkEventHandler = (e) => {
    const { value } = e.target;
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/searchField=[^&]*/, `searchField=${value}`);
    setListLinkState(tempLink);
  };

  const menuMap = searchField.map((obj, i) => {
    return (
      <MenuItem key={i} value={obj.id}>
        {obj.name}
      </MenuItem>
    );
  });
  return (
    <FormControl>
      <TextField
        name={"searchField"}
        className='m-2'
        variant='outlined'
        defaultValue={"all"}
        select
        size='small'
        label={"검색조건"}
        onChange={checkEventHandler}
        style={{ width: "80px" }}
      >
        {menuMap}
      </TextField>
    </FormControl>
  );
};
const KeywordForm = ({ searchClick }) => {
  const [listLinkState, setListLinkState] = useRecoilState(listLinkStateAtom);
  const [keyword, setKeyword] = useState("");
  const keywordFn = (e) => {
    const { value } = e.target;
    setKeyword(value);
    let tempLink = listLinkState;
    tempLink = tempLink.replace(/keyword=[^&]*/, `keyword=${encodeURI(value)}`);
    setListLinkState(tempLink);
  };
  const handleKeyPress = (event) => {
    event.key === "Enter" && searchClick();
  };
  return (
    <TextField
      variant='outlined'
      size='small'
      label={
        <span position='start'>
          검색어 &nbsp;
          <FontAwesomeIcon icon='fa-solid fa-search' size='xs' />
        </span>
      }
      type={"text"}
      placeholder='검색어'
      value={keyword}
      onChange={keywordFn}
      onKeyDown={handleKeyPress}
      className='m-2'
    />
  );
};

export const formatDate = (date) => {
  if (date === null) {
    return "";
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};
const payStatus = [
  { id: "all", name: "모두" },
  { id: "1", name: "승인" },
  { id: "2", name: "취소" },
  { id: "3", name: "실패" },
];

const settlementYn = [
  { id: "all", name: "모두" },
  { id: "N", name: "정산전" },
  { id: "P", name: "정산보류" },
  { id: "Y", name: "정산완료" },
];
const deductType = [
  { id: "all", name: "모두" },
  { id: "N", name: "차감전" },
  { id: "Y", name: "차감완료" },
];

const dateType = [
  { id: "settlementDate", name: "정산일기준" },
  { id: "paymentDate", name: "결제일기준" },
];
const withdraw = [
  { id: "all", name: "모두" },
  { id: "withdraw", name: "출금" },
  { id: "point", name: "적립금" },
];
const immediate = [
  { id: "all", name: "모두" },
  { id: "0", name: "없음" },
  { id: "692", name: "692" },
  { id: "1154", name: "1154" },
  { id: "1155", name: "1155" },
  { id: "2058", name: "2058" },
];
const contractStatus = [
  { id: "all", name: "모두" },
  { id: "1", name: "신청" },
  { id: "2", name: "정상" },
  { id: "3", name: "해지" },
];
