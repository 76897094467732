import {
  List,
  ListItem,
  ListItemText,
  Paper,
} from "../../../node_modules/@material-ui/core/index";
import { clsx } from "clsx";
import { useState } from "react";
import axios from "../../../node_modules/axios/index";
import { useEffect } from "react";

const ShortFooter = () => {
  // ^ ip
  const [IP, setIP] = useState("");
  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => getIP(), []);
  return (
    <Paper
      square
      elevation={2}
      className={clsx("app-footer text-black-50", {
        "app-footer--fixed": false,
        "app-footer--fixed__collapsed": false,
      })}
    >
      <div className="app-footer--inner">
        <div className="app-footer--first">
          {/* <List dense className="d-flex align-items-center">
            <ListItem className="rounded-sm text-nowrap" button>
              <ListItemText primary="Analytics" />
            </ListItem>
            <ListItem className="rounded-sm text-nowrap" button>
              <ListItemText primary="Wizards" />
            </ListItem>
            <ListItem className="rounded-sm text-nowrap" button>
              <ListItemText primary="CRM Manager" />
            </ListItem>
          </List> */}
          <span>MNWORKS 정산관리</span> © 2022
          {" 고고에프앤디"}
        </div>
        <div className="app-footer--second">
          <h6>접속 IP : {IP}</h6>
        </div>
      </div>
    </Paper>
  );
};

export default ShortFooter;
