import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Divider,
  InputAdornment,
  TextField,
  CardActions,
} from "@material-ui/core";
import { Grid } from "@mui/material";

import CommonTextFieldFullwidth from "components/commonInput/CommonTextField";
import { getAxios, postAxios } from "hook/axiosInstance";
import SearchFranchiseModal from "layouts/terminal/terminalCreate/SearchFranchiseModal";

import React, { useEffect, useState, useLayoutEffect } from "react";
import { formatToWon } from "utils/formatFunction";

const MerchantInput = ({}) => {
  const [remainPoint, setRemainPoint] = useState("");
  const getPointApi = async (selectedWithDrawIndex) => {
    selectedWithDrawIndex = selectedWithDrawIndex * 1 + 1;
    const pointRes = await getAxios(
      "merchant/account?index=" + selectedWithDrawIndex
    );
    const parseRes = JSON.parse(pointRes.data.data);
    if (pointRes.status === 200) {
      setRemainPoint(formatToWon(parseRes.data.balanceTotAmt));
    } else {
      setRemainPoint("출금 가능 금액을 불러올 수 없습니다.");
    }
  };

  //
  const bankList = [
    {
      value: "케이뱅크",
      label: "케이뱅크",
    },
  ];

  const [withDrawList, _] = useState([
    {
      value: 0,
      label: "692",
    },
    {
      value: 1,
      label: "1154",
    },
    {
      value: 2,
      label: "1155",
    },
    {
      value: 3,
      label: "2058",
    },
  ]);

  // ! 모달
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // ! 계좌인증
  const checkAccount = async () => {
    const validationData = {
      bankId: createValue?.bankName,
      bankOwnerName: createValue?.accountHolder,
      memAccntno: createValue?.accountNumber,
    };

    const validationRes = await postAxios(
      `franchise/account/check`,
      validationData
    );
    const parseRes = JSON.parse(validationRes.data.data);
    if (parseRes?.data.rsMsg == "정상거래") {
      setError({ ...error, accountApproval: false });
      setHelperText({ ...helperText, accountApproval: "계좌인증 완료" });
      alert("사용 가능한 코드입니다.");
      return;
    } else {
      setError({ ...error, accountApproval: true });
      setHelperText({ ...helperText, accountApproval: "계좌인증 필요" });
      alert("계좌인증에 실패했습니다");
      return;
    }
  };

  // ! 로직
  const [error, setError] = useState({ accountApproval: true });
  const [helperText, setHelperText] = useState({
    accountApproval: "계좌인증 필요",
  });

  const mid = sessionStorage.getItem("mid");
  const [createValue, setCreateValue] = useState({
    bankName: "케이뱅크",
    accountNumber: "700100042989",
    accountHolder: "(주)엠앤웍스",
    mid: mid,
    immediateWithdrawalKey: 0,
  });
  const handleChange = (e) => {
    setCreateValue({ ...createValue, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setRemainPoint("불러오는 중..");
    getPointApi(createValue.immediateWithdrawalKey);
  }, [createValue.immediateWithdrawalKey]);

  // ! 출금신청 요청
  const handleWithdrawl = async () => {
    if (!window.confirm("정말 출금하시겠습니까?")) {
      //^ 출금하시겠습니까 ? -> 아니오 -> 리턴
      return;
    }

    // if (validateWithdrawl(createValue) === false) {
    //   //^ 잔액이 부족한지 확인
    //   return;
    // }

    //^ 요청보냄

    const res = await postAxios("point/merchant", createValue);
    if (res.data.header.result_code === "200") {
      alert("출금 완료");
      window.location.reload();
    } else {
      alert("오류");
    }
  };

  // ! validation
  const validateWithdrawl = (createValue) => {
    if (createValue?.amount > remainPoint) {
      setError({ ...error, amount: "잔액이 부족합니다." });
      setHelperText({ ...helperText, amount: "잔액이 부족합니다." });
      alert("잔액이 부족합니다.");
      return false;
    }

    if (error?.accountApproval === true) {
      alert("계좌인증 필요");
      return false;
    }

    return true;
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={5}>
        <Card className="p-4 mb-4" sx={{}}>
          <div className="font-size-lg font-weight-bold">
            머천트 출금정보 입력
          </div>
          <Divider className="my-4" />
          <Grid container>
            <Grid item xs={12}>
              <TextField
                // sx={{ width: "1200px" }}
                className="m-2"
                id="immediateWithdrawalKey"
                name="immediateWithdrawalKey"
                variant="outlined"
                select
                fullWidth
                value={withDrawList[createValue.immediateWithdrawalKey].value}
                onChange={(event) => handleChange(event)}
                helperText={helperText?.immediateWithdrawalKey}
                error={error?.immediateWithdrawalKey}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      출금가상모계좌&nbsp;
                      <FontAwesomeIcon
                        icon="fa-solid fa-asterisk"
                        size="xs"
                        color="red"
                      />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "center",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                {withDrawList.map((option) => (
                  <li key={option.value} value={option.value}>
                    {option.label}
                  </li>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                fullWidth
                className="m-2"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      출금 가능 잔액&nbsp;
                    </InputAdornment>
                  ),
                }}
                value={remainPoint}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // sx={{ width: "1200px" }}
                className="m-2"
                id="bankName"
                name="bankName"
                variant="outlined"
                select
                fullWidth
                value={createValue?.bankName}
                onChange={handleChange}
                helperText={helperText?.bankName}
                error={error?.bankName}
                // defaultValue="중소기업은행"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      입금은행명&nbsp;
                      <FontAwesomeIcon
                        icon="fa-solid fa-asterisk"
                        size="xs"
                        color="red"
                      />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "center",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                {bankList.map((option) => (
                  <li key={option.value} value={option.value}>
                    {option.label}
                  </li>
                ))}
              </TextField>
              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  // handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="accountNumber"
                  meaning="계좌번호"
                  medium={true}
                  required={true}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  // handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="accountHolder"
                  meaning="예금주"
                  medium={true}
                  required={true}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="amount"
                  meaning="출금금액"
                  medium={true}
                  required={false}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <CommonTextFieldFullwidth
                  handleChange={handleChange}
                  createValue={createValue}
                  helperText={helperText}
                  error={error}
                  field="depositorName"
                  meaning="입금자명"
                  medium={true}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} className="m-2">
                <span>계좌인증여부</span>
                &nbsp;&nbsp;&nbsp;
                <span
                  className={
                    error?.accountApproval === true
                      ? "text-danger"
                      : "text-facebook"
                  }
                >
                  {helperText?.accountApproval}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              className="m-2"
              onClick={checkAccount}
            >
              계좌인증
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="m-2"
              onClick={handleWithdrawl}
            >
              출금신청
            </Button>
            {modalOpen ? (
              <SearchFranchiseModal
                modalOpen={true}
                handleModalClose={handleModalClose}
                createValue={createValue}
                setCreateValue={setCreateValue}
              />
            ) : null}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MerchantInput;
